/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Substream } from "@toruslabs/openlogin-jrpc";
import { ChunkStream } from "hooks/useCommunicationStream";
import i18n from "i18n";
import { EmbedConfiguration, EmbedTheme, MuxSubStream, OAuthModalData } from "interfaces/ui/IEmbed";
import torus from "libs/TorusExtended";
import log from "loglevel";
import pump from "pump";
import { embedActions, embedUiActions, setConfiguration, setEmbedTheme } from "shared/actions/embedAction";
import {
  handleLogin,
  logout,
  NetworkType,
  setProviderAsync,
  showProviderChangePopup,
  showUserInfoRequestPopup,
  subscribeController,
  triggerEmbed,
  walletActions
} from "shared/actions/walletAction";
import { MUX_CHUNK } from "shared/config/mux";
import { SUPPORTED_NETWORK_TYPES } from "shared/enums";
import { store } from "shared/store";
import { isMain } from "shared/utils/coreUtils";
import { decryptUserInfo } from "shared/utils/encryptionUtils";
// @ts-ignore
import * as stream from "stream-browserify";

export const initStream = () => {
  // should be non async function, because communication mux does not work in async func (only in safari) so no need to awaiting
  // communication mux is also should be extended @see: muxHelper.ts -> setupInternalMux
  if (!isMain) {
    const passthroughStream = new stream.PassThrough({ objectMode: true });
    passthroughStream?.on("data", (...arguments_: any) => {
      log.debug("[debug]: arguments_", arguments_);
    });

    const statusStream = torus.communicationMux?.getStream("status") as Substream;
    statusStream.on("data", (chunk: ChunkStream<OAuthModalData> & { loggedIn?: boolean; state?: string; verifier?: string }) => {
      if (chunk.loggedIn && chunk.state && chunk.verifier) {
        const decryptedState = decryptUserInfo(chunk.state);
        const userInfos: any[] = [];
        JSON.parse(decryptedState).map((item: any) => {
          userInfos.push(item.userInfo);
        });
        localStorage.setItem("userInfoSafari", JSON.stringify(userInfos));

        const getCachedUserInfo = () => {
          const vault = localStorage.getItem("cachedUserInfo");
          if (vault && Object.keys(JSON.parse(vault)).length > 0) {
            return JSON.parse(vault);
          } else {
            const sessionVault = sessionStorage.getItem("cachedUserInfo");
            if (sessionVault && Object.keys(JSON.parse(sessionVault)).length > 0) {
              return JSON.parse(sessionVault);
            } else {
              return {
                email: "",
                name: "",
                profileImage: "",
                typeOfLogin: "",
                verifier: "",
                verifierId: "",
                verifierParams: ""
              };
            }
          }
        };
        store.dispatch(
          handleLogin({
            calledFromEmbed: true,
            dappRedirectUrl: "",
            isUsingDirect: true,
            keys: JSON.parse(decryptedState) || [],
            skipDialog: false,
            userInfo: getCachedUserInfo(),
            verifier: chunk.verifier,
            login_hint: undefined,
            postboxKey: undefined,
            preopenInstanceId: undefined,
            localTransition: true
          })
        );
      }
      if (chunk.loggedIn) {
        handleStatus(chunk as any);
      }
    });

    const oAuthStream = torus.communicationMux?.getStream("oauth") as Substream;
    oAuthStream.on("data", (chunk: ChunkStream<OAuthModalData> & { selectedAddress?: string }) => {
      const { data, name } = chunk;
      if (name === "oauth_modal") {
        const widgetStream = torus.communicationMux?.getStream("widget") as Substream;
        widgetStream.write({
          data: true
        });
        store.dispatch(embedUiActions.setModalState(true));
      } else if (name === "oauth") {
        store.dispatch(
          triggerEmbed({
            calledFromEmbed: true,
            dappRedirectUrl: data.dappRedirectUrl,
            isUsingDirect: data.isUsingDirect,
            preopenInstanceId: data.preopenInstanceId,
            selectedVerifier: data.selectedVerifier,
            skipDialog: data.skipDialog,
            verifier: data.verifier,
            login_hint: undefined
          })
        );
      }
      if (chunk.selectedAddress) {
        store.dispatch(walletActions.setSelectedAddress(chunk.selectedAddress));
        torus.upbondController?.setSelectedAddress(chunk.selectedAddress);
        store.dispatch(subscribeController());
      }
    });

    pump(oAuthStream, passthroughStream, (error: any) => {
      throw new Error(error);
    });

    const initStream = torus.communicationMux?.getStream("init_stream") as Substream;
    initStream.setMaxListeners(50);
    initStream.on("data", async (chunk: ChunkStream<any & { whiteLabel: EmbedTheme }>) => {
      if (process.env.NODE_ENV === "development") {
        console.log(`@ on init stream -> `, chunk);
      }
      const {
        name,
        data: {
          apiKey = "torus-default",
          whiteLabel = {
            walletTheme: {
              lang: "en"
            }
          },
          buttonPosition = "",
          loginConfig = {},
          skipTKey = false,
          network = chunk && chunk.data ? chunk.data.network : SUPPORTED_NETWORK_TYPES.pol,
          skipDialog = false,
          selectedVerifier = "",
          directConfig = {
            enabled: false,
            redirectUrl: ""
          },
          widgetConfig = {
            showAfterLoggedIn: false,
            showBeforeLoggedIn: false
          }
        }
      } = chunk;
      store.dispatch(embedUiActions.setWidgetState(false));
      store.dispatch(
        embedActions.setIFrameConfig({
          ...store.getState().embedState.iFrameConfiguration,
          open: false
        })
      );
      if (name === "init_stream") {
        const isLocked = store.getState().wallet.prefs.isChainIdLocked;
        const widgetStream = torus.communicationMux?.getStream(MUX_CHUNK.WIDGETSTREAM) as MuxSubStream;
        if (widgetConfig && !widgetConfig.showBeforeLoggedIn) {
          widgetStream.write({
            data: false
          });
        }

        if (widgetConfig && !widgetConfig.showAfterLoggedIn && store.getState().wallet.selectedAddress !== "") {
          widgetStream.write({
            data: false
          });
        }

        store.dispatch(
          setConfiguration({
            apiKey,
            buttonPosition,
            directConfig,
            loginConfig,
            isOAuthModalVisible: false,
            loginInProgress: false,
            selectedVerifier,
            showWalletConnect: false,
            skipDialog,
            network,
            skipTKey,
            widgetConfig
          } as EmbedConfiguration)
        );

        if (whiteLabel.walletTheme.lang === "en" || whiteLabel.walletTheme.lang === "en-US") {
          i18n.changeLanguage("en");
          localStorage.setItem("lang", "en");
        } else {
          i18n.changeLanguage("ja");
          localStorage.setItem("lang", "ja");
        }

        if (whiteLabel.walletTheme.theme === "dark" || whiteLabel.walletTheme.theme === "light") {
          const myElement = document.getElementById("upbond-html");
          if (myElement) myElement.style.colorScheme = whiteLabel.walletTheme.theme;
          localStorage.setItem("torus-theme", whiteLabel.walletTheme.theme);
        }

        const networkType = store.getState().wallet.networkType;
        if (networkType.host !== network.host && !isLocked) {
          store.dispatch(walletActions.setNetworkType({ ...network }));
          await setProviderAsync({
            network
          });
        }
        if (Object.values(whiteLabel as EmbedTheme).length > 0) {
          const embedTheme: EmbedTheme = whiteLabel?.walletTheme;
          store.dispatch(
            setEmbedTheme({
              ...(embedTheme as EmbedTheme)
            })
          );
        }
        initStream.write({
          name: "init_complete",
          data: { success: true }
        });
      }
    });

    const widgetVisibilityStream = torus.communicationMux?.getStream(MUX_CHUNK.WIDGETVISIBILITYSTREAM) as Substream;
    widgetVisibilityStream.on("data", (chunk: { data: boolean }) => {
      const {
        embedState: { configuration },
        wallet: { selectedAddress }
      } = store.getState();
      const isLoggedIn = selectedAddress !== "";
      if (chunk.data === true && !isLoggedIn) {
        store.dispatch(
          setConfiguration({
            ...configuration,
            widgetConfig: {
              ...configuration.widgetConfig,
              showBeforeLoggedIn: true
            }
          })
        );
      } else if (chunk.data === true && isLoggedIn) {
        store.dispatch(
          setConfiguration({
            ...configuration,
            widgetConfig: {
              ...configuration.widgetConfig,
              showAfterLoggedIn: true
            }
          })
        );
      } else if (chunk.data === false && !isLoggedIn) {
        store.dispatch(
          setConfiguration({
            ...configuration,
            widgetConfig: {
              ...configuration.widgetConfig,
              showBeforeLoggedIn: false
            }
          })
        );
      } else if (chunk.data === false && isLoggedIn) {
        store.dispatch(
          setConfiguration({
            ...configuration,
            widgetConfig: {
              ...configuration.widgetConfig,
              showAfterLoggedIn: false
            }
          })
        );
      }
    });

    const authServiceStream = torus.communicationMux?.getStream(MUX_CHUNK.AUTHSERVICESTREAM) as Substream;
    authServiceStream.on("data", (ev) => {
      if (ev.name && ev.name === "access_token_request" && store.getState().wallet.authServiceAccessToken !== null) {
        authServiceStream.write({
          name: "result",
          data: { authServiceAccessToken: store.getState().wallet.authServiceAccessToken }
        });
      } else {
        authServiceStream.write({
          name: "error",
          data: { msg: "Your domain is blocked" }
        });
      }
    });

    const userInfoStream = torus.communicationMux?.getStream(MUX_CHUNK.USERINFOSTREAM) as MuxSubStream;
    userInfoStream.setMaxListeners(50);
    userInfoStream.on(
      "data",
      (
        chunk: ChunkStream<{
          message: string;
          preopenInstanceId: string;
        }>
      ) => {
        if (chunk.name === "user_info_request") {
          showUserInfoRequestPopup(
            chunk.data as {
              message: string;
              preopenInstanceId: string;
            },
            {
              userInfoStream
            }
          );
        }
      }
    );

    const logoutStream = torus.communicationMux?.getStream(MUX_CHUNK.LOGOUT) as Substream;
    logoutStream.setMaxListeners(50);
    logoutStream.on("data", (chunk: ChunkStream) => {
      if (chunk.name === "logOut") store.dispatch(logout());
    });

    const userInfoAccessStream = torus.communicationMux?.getStream(MUX_CHUNK.USERINFOACCESSSTREAM) as Substream;
    userInfoAccessStream.setMaxListeners(50);
    const { userInfo, newUser } = store.getState().wallet;
    userInfoAccessStream.on("data", (chunk: ChunkStream) => {
      let payload = { ...userInfo, isNewUser: newUser };
      delete payload.verifierParams;

      if (!payload.email) {
        const getFromLocalStorage = JSON.parse(localStorage.getItem("userInfoSafari") || "[]");
        if (getFromLocalStorage.length > 0) {
          getFromLocalStorage.map((item: any) => {
            payload = item;
          });
        }
      }
      if (chunk.name === "user_info_access_request") {
        userInfoAccessStream.write({ name: "user_info_access_response", data: { approved: true, payload } });
      }
    });

    const walletStream = torus.communicationMux?.getStream(MUX_CHUNK.WALLETSTREAM) as Substream;
    walletStream.setMaxListeners(50);
    walletStream.on("data", (chunk: ChunkStream) => {
      if (chunk.name === "show_wallet") walletStream.write({ name: "show_wallet_instance", data: { instanceId: torus.instanceId } });
    });

    const providerChangeStream = torus.communicationMux?.getStream(MUX_CHUNK.PROVIDERCHANGESTREAM) as MuxSubStream;
    providerChangeStream.on(
      "data",
      async (
        chunk: ChunkStream<{
          network: NetworkType;
          preopenInstanceId: string;
          override: boolean;
        }>
      ) => {
        if (chunk.name === "show_provider_change") {
          const {
            data: { network, override, preopenInstanceId }
          } = chunk;

          await showProviderChangePopup({
            network,
            override,
            preopenInstanceId,
            providerChangeStream
          });
        }
      }
    );
  }
};

export const handleStatus = ({ loggedIn, rehydrate, verifier }: { loggedIn: boolean; rehydrate?: boolean; verifier?: string }) => {
  const statusStream = torus.communicationMux?.getStream("status") as Substream;
  if (statusStream) {
    statusStream.write({
      loggedIn,
      rehydrate,
      verifier
    });
  }
};
