// @ts-nocheck
/* eslint-disable jsx-a11y/media-has-caption */
import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonButton,
  IonCol,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonThumbnail
} from "@ionic/react";
import { useAppDispatch } from "hooks/useRedux";
import { fetchSelectedHistoryChain } from "shared/actions/historyChain";
import { numberParse } from "shared/utils/etherUtils";
import { HomeListTokenContentProps } from "features/home/types";
import { IToken } from "interfaces/actions/IToken";
import { chevronForwardOutline, chevronUp } from "ionicons/icons";
import { defaultLogo } from "helpers/httpHelpers";
import { useTranslation } from "react-i18next";
import useLoading from "hooks/useLoading";
import VideoIcon from "assets/icons/Video.svg";

const HomeListMb: React.FC<any> = ({
  balances,
  page,
  index,
  balance,
  onCoinClick,
  tokenBalances,
  onTokenClick,
  collectibles,
  onNFTClick,
  length,
  balanceOpen,
  setBalanceOpen,
  openNFT
}: HomeListTokenContentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { SkeletonLoading } = useLoading();

  const checkLoadingState = () => {
    if (index || length) {
      if (index === length - 1) {
        return (
          <div className="mt-12 text-center">
            <p>{t("home.noNftData")}</p>
          </div>
        );
      } else {
        return (
          <div className="mt-4 w-full justify-center">
            <SkeletonLoading type="group" rows={4} />
          </div>
        );
      }
    } else {
      return (
        <div className="mt-12 text-center">
          <p>{t("home.noNftData")}</p>
        </div>
      );
    }
  };

  return (
    <>
      <IonList lines="full" className="m-0">
        <>
          {/* Start Erc20 INIT */}
          {balances.map((el: IToken, i) =>
            page === "transfer" ? (
              parseFloat(el.balance) > 0 ? (
                (el.testnet && !balance.showTestnet) || i > index ? null : (
                  <IonItem
                    key={el.contract_address + i}
                    className="p-0"
                    id={`ticker-${el.contract_ticker_symbol}`}
                    onClick={() => {
                      onCoinClick(el);
                    }}>
                    <IonAvatar slot="start">
                      <IonImg className="border border-black" alt={el.contract_address} src={el.logo_url} />
                      <IonImg
                        className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                        alt={el.contract_address}
                        src={el.logo_url}
                      />
                    </IonAvatar>
                    <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                    <IonLabel slot="end" className="font-bold">
                      {numberParse(el.balance, el.contract_decimals)}
                      {"  "}
                      {el.contract_ticker_symbol}
                    </IonLabel>
                  </IonItem>
                )
              ) : null
            ) : (el.testnet && !balance.showTestnet) || i > index ? null : (
              <IonItem
                key={el.contract_address + i}
                className="p-0"
                id={`ticker-${el.contract_ticker_symbol}`}
                onClick={() => {
                  dispatch(fetchSelectedHistoryChain(el.chain_id));
                  onCoinClick(el);
                }}>
                {el.native_token}
                <IonAvatar slot="start">
                  <IonImg className="border border-black" alt={el.contract_address} src={el.logo_url} />
                  <IonImg
                    className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                    alt={el.contract_address}
                    src={el.logo_url}
                  />
                </IonAvatar>
                <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                {/* <IonLabel slot="end" className="font-bold">
                      {numberParse(el.balance, el.contract_decimals)}
                      {"  "}
                      {el.contract_ticker_symbol}
                    </IonLabel> */}
                <IonIcon id="chevron-forward-outline" color="dark" slot="end" size="small" icon={chevronForwardOutline} />
              </IonItem>
            )
          )}
          {/* End Erc20 INIT */}

          {/* Start Nft Init */}
          {balanceOpen &&
            tokenBalances.map((el, i) =>
              el.testnet && !balance.showTestnet
                ? null
                : page === "transfer"
                ? parseFloat(el.balance) > 0 &&
                  el.contract_name && (
                    <IonItem
                      key={el.contract_address + i}
                      className="p-0"
                      onClick={() => {
                        onTokenClick(el);
                      }}>
                      <IonAvatar slot="start">
                        <IonImg className="border border-black" alt={el.contract_address} src={defaultLogo(el.chain_id)} />
                        <IonImg
                          className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                          alt={el.contract_address}
                          src={el.logo_url}
                        />
                      </IonAvatar>
                      <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                      <IonLabel slot="end" className="font-bold">
                        {numberParse(el.balance, el.contract_decimals)}
                        {"  "}
                        {el.contract_ticker_symbol}
                      </IonLabel>
                    </IonItem>
                  )
                : el.testnet && !balance.showTestnet
                ? null
                : el.contract_name && (
                    <IonItem
                      key={el.contract_address + i}
                      className="p-0"
                      onClick={() => {
                        onTokenClick(el);
                      }}>
                      <IonAvatar slot="start">
                        <IonImg className="border border-black" alt={el.contract_address} src={defaultLogo(el.chain_id)} />
                        <IonImg
                          className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                          alt={el.contract_address}
                          src={el.logo_url}
                        />
                      </IonAvatar>
                      <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                      {/* <IonLabel slot="end" className="font-bold">
                            {numberParse(el.balance, el.contract_decimals)}
                            {"  "}
                            {el.contract_ticker_symbol}
                          </IonLabel> */}
                      <IonIcon id="chevron-forward-outline" color="dark" slot="end" size="small" icon={chevronForwardOutline} />
                    </IonItem>
                  )
            )}
          {/* End Nft Init */}
        </>
      </IonList>

      {index < length - 1 && (
        <div className="mt-4 w-full justify-center">
          <SkeletonLoading type="group" rows={1} />
        </div>
      )}

      {tokenBalances.length > 0 ? (
        <IonButton
          shape="round"
          className="all-home ion-margin-top"
          onClick={() => (balanceOpen ? setBalanceOpen(false) : setBalanceOpen(true))}>
          {balanceOpen ? (
            <>
              <IonIcon slot="end" icon={chevronUp} color="primary" />
              {t("home.less")}
            </>
          ) : (
            <>
              <IonIcon slot="end" icon={chevronForwardOutline} color="primary" />
              {t("home.all")}
            </>
          )}
        </IonButton>
      ) : (
        <br></br>
      )}

      <IonAccordionGroup className="mt-2.5">
        <IonLabel className="font-bold">{t("home.collectibles")}</IonLabel>
        {collectibles.length > 0
          ? collectibles.map((item: IToken, i: number) =>
              item.testnet && !balance.showTestnet
                ? null
                : item.nft_data &&
                  item.nft_data && (
                    <IonAccordion key={i} value={item.contract_address + i} className="w-full m-0 pt-[5px]">
                      <IonItem slot="header" color="light" className="rounded-none border-b-[1px] border-b-zinc-200" lines="none">
                        <IonAvatar slot="start">
                          {item.nft_data.map(
                            (nft: any, i: number) =>
                              i === 0 &&
                              nft.external_data && (
                                <img
                                  key={i}
                                  className="object-cover h-[36px] w-[36px]"
                                  alt={"NFT"}
                                  src={
                                    nft.external_data.thumbnail_url || nft?.external_data?.image
                                      ? nft.external_data.thumbnail_url || nft.external_data.image
                                      : window.location.origin + "/assets/noimg.png"
                                  }
                                  onError={(e) => {
                                    e.currentTarget.src = window.location.origin + "/assets/noimg.png";
                                  }}
                                />
                              )
                          )}
                        </IonAvatar>
                        {item.contract_address === "0x939Cf6D4Dab42e9B64bD3E7251E4BBb212d8ba82".toLocaleLowerCase() ||
                        item.contract_address === "0x3c9161C06e8EED26aCBEdF57f1233b7a29bD2685".toLocaleLowerCase() ? (
                          <IonLabel className="font-bold">2023年新入社員証明NFT</IonLabel>
                        ) : (
                          <IonLabel className="font-bold">{item.contract_name ?? t("home.undefinedCollection")}</IonLabel>
                        )}
                        <IonLabel className="font-bold" slot="end">
                          {item.nft_data.length}
                        </IonLabel>
                      </IonItem>
                      <div slot="content" className="shadow-none">
                        <IonRow>
                          {item.nft_data.map((nft: any) => (
                            <IonCol
                              key={item.contract_address + nft.token_id}
                              size="6"
                              onClick={() => {
                                page === "transfer" ? onNFTClick(item, nft) : openNFT(item, nft);
                              }}
                              className="flex justify-center h-max">
                              <IonThumbnail className="w-full h-full rounded-2xl overflow-hidden">
                                {nft?.external_data?.image ? (
                                  <div className="relative w-full">
                                    <img
                                      className="w-full"
                                      alt={
                                        nft?.external_data?.image ? nft?.external_data?.image : window.location.origin + "/assets/noimg.png"
                                      }
                                      src={
                                        nft?.external_data?.image ? nft?.external_data?.image : window.location.origin + "/assets/noimg.png"
                                      }
                                    />
                                    {nft?.external_data?.animation_url && (
                                      <img
                                        className={`absolute left-0 top-[4vh] z-10 opacity-100 w-full h-[6vh]`}
                                        src={VideoIcon}
                                        alt="video icon"
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <video autoPlay muted>
                                    <source src={nft?.external_data?.animation_url} />
                                  </video>
                                )}
                              </IonThumbnail>

                              <IonLabel className="absolute h-full flex items-end text-white-background py-3 px-4 bottom-[5px] z-50 bg-[linear-gradient(180deg,_#FFFFFF00_2%,_#00000036_100%)] rounded-b-2xl w-[95%]">
                                {nft.external_data.name}
                              </IonLabel>
                            </IonCol>
                          ))}
                        </IonRow>
                      </div>
                    </IonAccordion>
                  )
            )
          : checkLoadingState()}
      </IonAccordionGroup>
    </>
  );
};

export default HomeListMb;
