import { IChains, IToken } from "interfaces/actions/IToken";
import { PolygonMatic } from "assets/icons";

// list of chain - 8
const listChainDev: IChains[] = [
  {
    name: "Ethereum Mainnet",
    ticker: "ETH",
    rpcUrl: [
      "https://eth-mainnet.public.blastapi.io",
      "https://cloudflare-eth.com",
      "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      "https://eth-mainnet.gateway.pokt.network/v1/5f3453978e354ab992c4da79",
      "https://eth-rpc.gateway.pokt.network",
      "https://rpc.ankr.com/eth",
      "https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7"
    ],
    explore: "https://etherscan.io/",
    chainName: "eth",
    addressDefinitions: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    chain: "1",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    customLogo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    customName: "Ethereum",
    customTicker: "ETH",
    resource: "https://opensea.io/assets/ethereum/",
    type: "mainnet",
    chainId: "0x1",
    gasConfig: {
      chainId: 1,
      percentile: 15, // Which percentile of effective priority fees to include
      blocksCount: 10, // How many blocks to consider for priority fee estimation
      defaultRpc: "https://eth-mainnet.public.blastapi.io",
      blockTime: 10, // seconds
      shouldCache: false,
      timeout: 10000, // specifies the number of milliseconds before the request times out.
      minPriority: 0, // specifies the min maxPriorityFeePerGas.
      fallbackGasPrices: {
        gasPrices: {
          instant: 28,
          fast: 22,
          standard: 17,
          low: 11
        },
        estimated: {
          baseFee: undefined,
          maxFeePerGas: 20,
          maxPriorityFeePerGas: 3
        }
      }
    }
  },
  {
    name: "Pol Mainnet",
    ticker: "POL",
    rpcUrl: ["https://polygon.llamarpc.com", "https://polygon-rpc.com", "https://rpc.ankr.com/polygon"],
    explore: "https://polygonscan.com/",
    chainName: "polygon",
    addressDefinitions: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    chain: "137",
    address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    customLogo: PolygonMatic,
    customName: "Pol",
    customTicker: "POL",
    resource: "https://opensea.io/assets/matic/",
    type: "mainnet",
    chainId: "0x137",
    gasConfig: {
      chainId: 137,
      percentile: 15, // Which percentile of effective priority fees to include
      blocksCount: 10, // How many blocks to consider for priority fee estimation
      defaultRpc: "https://polygon-mainnet.public.blastapi.io",
      blockTime: 10, // seconds
      shouldCache: false,
      timeout: 10000, // specifies the number of milliseconds before the request times out.
      minPriority: 0, // specifies the min maxPriorityFeePerGas.
      fallbackGasPrices: {
        gasPrices: {
          instant: 28,
          fast: 22,
          standard: 17,
          low: 11
        },
        estimated: {
          baseFee: undefined,
          maxFeePerGas: 20,
          maxPriorityFeePerGas: 3
        }
      }
    }
  },
  {
    name: "Astar",
    ticker: "ASTR",
    rpcUrl: [
      "https://astar.public.blastapi.io",
      "https://astar.api.onfinality.io/public",
      "https://evm.astar.network",
      "https://1rpc.io/astr"
    ],
    explore: "https://astar.subscan.io/",
    chainName: "Astar",
    addressDefinitions: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    chain: "592",
    address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    customLogo: "https://s2.coinmarketcap.com/static/img/coins/64x64/12885.png",
    customName: "Astar",
    customTicker: "ASTR",
    resource: "https://tofunft.com/nft/astar/",
    type: "mainnet",
    gasConfig: {
      chainId: 592,
      percentile: 15, // Which percentile of effective priority fees to include
      blocksCount: 10, // How many blocks to consider for priority fee estimation
      defaultRpc: "https://astar.public.blastapi.io",
      blockTime: 2, // seconds
      shouldCache: false,
      timeout: 10000, // specifies the number of milliseconds before the request times out.
      minPriority: 0, // specifies the min maxPriorityFeePerGas.
      fallbackGasPrices: {
        gasPrices: {
          instant: 28,
          fast: 22,
          standard: 17,
          low: 11
        },
        estimated: {
          baseFee: undefined,
          maxFeePerGas: 20,
          maxPriorityFeePerGas: 3
        }
      }
    }
  },
  {
    name: "Pol Amoy",
    ticker: "TPOL",
    rpcUrl: [
      "https://polygon-amoy-bor-rpc.publicnode.com",
      "https://rpc-amoy.polygon.technology",
      "https://polygon-bor-amoy-rpc.publicnode.com"
    ],
    explore: "https://www.oklink.com/amoy/",
    chainName: "amoy",
    addressDefinitions: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    chain: "80002",
    address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    customLogo: PolygonMatic,
    customName: "Amoy",
    customTicker: "POL",
    resource: "https://testnets.opensea.io/assets/amoy/",
    type: "testnet",
    chainId: "0x13882",
    gasConfig: {
      chainId: 80002,
      percentile: 15, // Which percentile of effective priority fees to include
      blocksCount: 10, // How many blocks to consider for priority fee estimation
      defaultRpc: "https://rpc-amoy.polygon.technology",
      blockTime: 5, // seconds
      shouldCache: false,
      timeout: 10000, // specifies the number of milliseconds before the request times out.
      minPriority: 5, // specifies the min maxPriorityFeePerGas.
      fallbackGasPrices: {
        gasPrices: {
          instant: 28,
          fast: 22,
          standard: 17,
          low: 11
        },
        estimated: {
          baseFee: undefined,
          maxFeePerGas: 20,
          maxPriorityFeePerGas: 3
        }
      }
    }
  },
  // {
  //   name: "Goerli Testnet",
  //   ticker: "ETH",
  //   rpcUrl: [
  //     // "https://goerli.blockpi.network/v1/rpc/public",
  //     // "https://eth-goerli.public.blastapi.io",
  //     "https://rpc.ankr.com/eth_goerli",
  //     "https://goerli.infura.io/v3/2abf317ac68f47b1890e187c552dcdc1",
  //     "https://goerli.infura.io/v3/81984a3dbd7d4446886a8add0f51aa79",
  //     "https://goerli.infura.io/v3/74a97bae118345ecbadadaaeb1cf4a53",
  //     "https://eth-goerli.public.blastapi.io",
  //     "https://goerli.blockpi.network/v1/rpc/public"
  //   ],
  //   explore: "https://goerli.etherscan.io/",
  //   chainName: "goerli",
  //   addressDefinitions: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  //   chain: "5",
  //   address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  //   customLogo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  //   customName: "Goerli",
  //   customTicker: "ETH",
  //   resource: "https://testnets.opensea.io/assets/goerli/",
  //   type: "testnet",
  //   chainId: "0x5",
  //   gasConfig: {
  //     chainId: 5,
  //     percentile: 15, // Which percentile of effective priority fees to include
  //     blocksCount: 10, // How many blocks to consider for priority fee estimation
  //     defaultRpc: "https://rpc.ankr.com/eth_goerli",
  //     blockTime: 10, // seconds
  //     shouldCache: false,
  //     timeout: 10000, // specifies the number of milliseconds before the request times out.
  //     minPriority: 5, // specifies the min maxPriorityFeePerGas.
  //     fallbackGasPrices: {
  //       gasPrices: {
  //         instant: 28,
  //         fast: 22,
  //         standard: 17,
  //         low: 11
  //       },
  //       estimated: {
  //         baseFee: undefined,
  //         maxFeePerGas: 20,
  //         maxPriorityFeePerGas: 3
  //       }
  //     }
  //   }
  // },
  {
    name: "Sepolia Testnet",
    ticker: "ETH",
    rpcUrl: ["https://endpoints.omniatech.io/v1/eth/sepolia/public", "https://ethereum-sepolia-rpc.publicnode.com"],
    explore: "https://sepolia.etherscan.io/",
    chainName: "sepolia",
    addressDefinitions: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    chain: "11155111",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    customLogo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    customName: "Sepolia",
    customTicker: "ETH",
    resource: "https://testnets.opensea.io/assets/sepolia/",
    type: "testnet",
    chainId: "0xaa36a7",
    gasConfig: {
      chainId: 11155111,
      percentile: 15, // Which percentile of effective priority fees to include
      blocksCount: 10, // How many blocks to consider for priority fee estimation
      defaultRpc: "https://ethereum-sepolia-rpc.publicnode.com",
      blockTime: 10, // seconds
      shouldCache: false,
      timeout: 10000, // specifies the number of milliseconds before the request times out.
      minPriority: 5, // specifies the min maxPriorityFeePerGas.
      fallbackGasPrices: {
        gasPrices: {
          instant: 28,
          fast: 22,
          standard: 17,
          low: 11
        },
        estimated: {
          baseFee: undefined,
          maxFeePerGas: 20,
          maxPriorityFeePerGas: 3
        }
      }
    }
  }
];

// const listChainProd: IChains[] = [
//   {
//     name: "Ethereum Mainnet",
//     ticker: "ETH",
//     rpcUrl: [
//       "https://eth-mainnet.public.blastapi.io",
//       "https://cloudflare-eth.com",
//       "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
//       "https://eth-mainnet.gateway.pokt.network/v1/5f3453978e354ab992c4da79",
//       "https://eth-rpc.gateway.pokt.network",
//       "https://rpc.ankr.com/eth",
//       "https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7",
//       "https://rpc.flashbots.net"
//     ],
//     explore: "https://etherscan.io/",
//     chainName: "eth",
//     addressDefinitions: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
//     chain: "1",
//     address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
//     customLogo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
//     customName: "Ethereum",
//     customTicker: "ETH",
//     resource: "https://opensea.io/assets/ethereum/",
//     type: "mainnet",
//     gasConfig: {
//       chainId: 1,
//       percentile: 15, // Which percentile of effective priority fees to include
//       blocksCount: 10, // How many blocks to consider for priority fee estimation
//       defaultRpc: "https://eth-mainnet.public.blastapi.io",
//       blockTime: 10, // seconds
//       shouldCache: false,
//       timeout: 10000, // specifies the number of milliseconds before the request times out.
//       minPriority: 0, // specifies the min maxPriorityFeePerGas.
//       fallbackGasPrices: {
//         gasPrices: {
//           instant: 28,
//           fast: 22,
//           standard: 17,
//           low: 11
//         },
//         estimated: {
//           baseFee: undefined,
//           maxFeePerGas: 20,
//           maxPriorityFeePerGas: 3
//         }
//       }
//     }
//   },
//   {
//     name: "Matic Mainnet",
//     ticker: "MATIC",
//     rpcUrl: ["https://polygon-rpc.com", "https://rpc.ankr.com/polygon"],
//     explore: "https://polygonscan.com/",
//     chainName: "polygon",
//     addressDefinitions: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
//     chain: "137",
//     address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
//     customLogo: PolygonMatic,
//     customName: "Matic",
//     customTicker: "MATIC",
//     resource: "https://opensea.io/assets/matic/",
//     type: "mainnet",
//     gasConfig: {
//       chainId: 137,
//       percentile: 15, // Which percentile of effective priority fees to include
//       blocksCount: 10, // How many blocks to consider for priority fee estimation
//       defaultRpc: "https://polygon-mainnet.public.blastapi.io",
//       blockTime: 10, // seconds
//       shouldCache: false,
//       timeout: 10000, // specifies the number of milliseconds before the request times out.
//       minPriority: 0, // specifies the min maxPriorityFeePerGas.
//       fallbackGasPrices: {
//         gasPrices: {
//           instant: 28,
//           fast: 22,
//           standard: 17,
//           low: 11
//         },
//         estimated: {
//           baseFee: undefined,
//           maxFeePerGas: 20,
//           maxPriorityFeePerGas: 3
//         }
//       }
//     }
//   },
//   {
//     name: "Astar",
//     ticker: "ASTR",
//     rpcUrl: [
//       "https://astar.public.blastapi.io",
//       "https://astar.api.onfinality.io/public",
//       "https://evm.astar.network",
//       "https://1rpc.io/astr"
//     ],
//     explore: "https://astar.subscan.io/",
//     chainName: "Astar",
//     addressDefinitions: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
//     chain: "592",
//     address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
//     customLogo: "https://s2.coinmarketcap.com/static/img/coins/64x64/12885.png",
//     customName: "Astar",
//     customTicker: "ASTR",
//     resource: "https://tofunft.com/nft/astar/",
//     type: "mainnet",
//     gasConfig: {
//       chainId: 592,
//       percentile: 15, // Which percentile of effective priority fees to include
//       blocksCount: 10, // How many blocks to consider for priority fee estimation
//       defaultRpc: "https://astar.public.blastapi.io",
//       blockTime: 2, // seconds
//       shouldCache: false,
//       timeout: 10000, // specifies the number of milliseconds before the request times out.
//       minPriority: 0, // specifies the min maxPriorityFeePerGas.
//       fallbackGasPrices: {
//         gasPrices: {
//           instant: 28,
//           fast: 22,
//           standard: 17,
//           low: 11
//         },
//         estimated: {
//           baseFee: undefined,
//           maxFeePerGas: 20,
//           maxPriorityFeePerGas: 3
//         }
//       }
//     }
//   }
// ];

export const listChain = listChainDev;

export const initialBalanceOrder: IToken[] = [
  {
    contract_decimals: 18,
    contract_name: "Ethereum",
    contract_ticker_symbol: "ETH",
    contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    supports_erc: null,
    logo_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    last_transferred_at: null,
    native_token: true,
    type: "dust",
    balance: "0",
    balance_24h: "0",
    quote_rate: 1213.84,
    quote_rate_24h: 1212.5494,
    quote: 0,
    quote_24h: 0,
    nft_data: [],
    chain_id: "1",
    testnet: false,
    chainId: "0x1"
  },
  {
    contract_decimals: 18,
    contract_name: "Pol",
    contract_ticker_symbol: "POL",
    contract_address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    supports_erc: ["erc20"],
    logo_url: PolygonMatic,
    last_transferred_at: null,
    native_token: true,
    type: "cryptocurrency",
    balance: "0",
    balance_24h: "0",
    quote_rate: 0.78966844,
    quote_rate_24h: 0.79377747,
    quote: 0.07234083,
    quote_24h: 0.07271726,
    nft_data: [],
    chain_id: "137",
    testnet: false,
    chainId: "0x89"
  },
  {
    contract_decimals: 18,
    contract_name: "Astar",
    contract_ticker_symbol: "ASTR",
    contract_address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    supports_erc: null,
    logo_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/12885.png",
    last_transferred_at: null,
    native_token: true,
    type: "dust",
    balance: "0",
    balance_24h: "0",
    quote_rate: 0.040013008,
    quote_rate_24h: 0,
    quote: 0,
    quote_24h: 0,
    nft_data: [],
    chain_id: "592",
    testnet: false,
    chainId: "0x250"
  },
  {
    contract_decimals: 18,
    contract_name: "Amoy",
    contract_ticker_symbol: "POL",
    contract_address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    supports_erc: ["erc20"],
    logo_url: PolygonMatic,
    last_transferred_at: null,
    native_token: true,
    type: "dust",
    balance: "0",
    balance_24h: "0",
    quote_rate: 0,
    quote_rate_24h: 0,
    quote: 0,
    quote_24h: 0,
    nft_data: [],
    chain_id: "80002",
    testnet: true,
    chainId: "0x13882"
  },
  // {
  //   contract_decimals: 18,
  //   contract_name: "Goerli",
  //   contract_ticker_symbol: "ETH",
  //   contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  //   supports_erc: null,
  //   logo_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  //   last_transferred_at: null,
  //   native_token: true,
  //   type: "dust",
  //   balance: "0",
  //   balance_24h: "0",
  //   quote_rate: 0,
  //   quote_rate_24h: 0,
  //   quote: 0,
  //   quote_24h: 0,
  //   nft_data: [],
  //   chain_id: "5",
  //   testnet: true,
  //   chainId: "0x5"
  // },
  {
    contract_decimals: 18,
    contract_name: "Sepolia",
    contract_ticker_symbol: "ETH",
    contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    supports_erc: null,
    logo_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    last_transferred_at: null,
    native_token: true,
    type: "dust",
    balance: "0",
    balance_24h: "0",
    quote_rate: 0,
    quote_rate_24h: 0,
    quote: 0,
    quote_24h: 0,
    nft_data: [],
    chain_id: "11155111",
    testnet: true,
    chainId: "0xaa36a7"
  }
];

export const initialBalanceOrderProd: IToken[] = [
  {
    contract_decimals: 18,
    contract_name: "Ethereum",
    contract_ticker_symbol: "ETH",
    contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    supports_erc: null,
    logo_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    last_transferred_at: null,
    native_token: true,
    type: "dust",
    balance: "0",
    balance_24h: "0",
    quote_rate: 1213.84,
    quote_rate_24h: 1212.5494,
    quote: 0,
    quote_24h: 0,
    nft_data: [],
    chain_id: "1",
    testnet: false
  },
  {
    contract_decimals: 18,
    contract_name: "Pol",
    contract_ticker_symbol: "POL",
    contract_address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    supports_erc: ["erc20"],
    logo_url: PolygonMatic,
    last_transferred_at: null,
    native_token: true,
    type: "cryptocurrency",
    balance: "0",
    balance_24h: "0",
    quote_rate: 0.78966844,
    quote_rate_24h: 0.79377747,
    quote: 0.07234083,
    quote_24h: 0.07271726,
    nft_data: [],
    chain_id: "137",
    testnet: false
  },
  {
    contract_decimals: 18,
    contract_name: "Astar",
    contract_ticker_symbol: "ASTR",
    contract_address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    supports_erc: null,
    logo_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/12885.png",
    last_transferred_at: null,
    native_token: true,
    type: "dust",
    balance: "0",
    balance_24h: "0",
    quote_rate: 0.040013008,
    quote_rate_24h: 0,
    quote: 0,
    quote_24h: 0,
    nft_data: [],
    chain_id: "592",
    testnet: false
  }
];
