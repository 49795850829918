import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmbedState } from "interfaces/ui";
import { EmbedConfiguration, EmbedTheme, EmbedUiState } from "interfaces/ui/IEmbed";
import config from "shared/config";
import { POL, SUPPORTED_NETWORK_TYPES } from "shared/enums";

const initEmbed: EmbedState = {
  configuration: {
    loginConfig: config.loginConfig,
    isOAuthModalVisible: false,
    buttonPosition: "bottom-left",
    apiKey: "torus-default",
    skipTKey: false,
    loginInProgress: false,
    showWalletConnect: false,
    directConfig: {
      enabled: false,
      redirectUrl: ""
    },
    selectedVerifier: "",
    skipDialog: false,
    network: SUPPORTED_NETWORK_TYPES[POL],
    widgetConfig: {
      showAfterLoggedIn: true,
      showBeforeLoggedIn: false
    }
  },
  embedTheme: {
    termsLink: {
      privacyPolicy: "https://www.upbond.io/privacy-policy",
      termsCondition: "https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799"
    },
    logo: "https://i.ibb.co/L6vHB5d/company-logo-sample.png",
    name: "Company",
    buttonLogo: "https://auth-service-public.s3.ap-northeast-1.amazonaws.com/assets/sample-button-logo.png",
    isActive: true,
    modalColor: "#fffff",
    bgColor: "#4B68AE",
    bgColorHover: "#214999",
    textColor: "#f3f3f3",
    textColorHover: "#ffffff",
    upbondLogin: {
      globalBgColor: "#ffffff",
      globalTextColor: "#000000"
    }
  },
  iFrameConfiguration: {
    open: false,
    origin: "",
    src: "",
    topCalc: 3
  },
  popupPayload: {}
};

const embedSlice = createSlice({
  name: "embedState",
  initialState: initEmbed,
  reducers: {
    setConfiguration: (state, action: PayloadAction<EmbedConfiguration>) => {
      state.configuration = action.payload;
    },
    resetEmbedConfiguration: (state) => {
      state.configuration = {
        loginConfig: config.loginConfig,
        isOAuthModalVisible: false,
        buttonPosition: "bottom-left",
        apiKey: "torus-default",
        skipTKey: false,
        loginInProgress: false,
        showWalletConnect: false,
        directConfig: {
          enabled: false,
          redirectUrl: ""
        },
        selectedVerifier: "",
        skipDialog: false,
        network: SUPPORTED_NETWORK_TYPES[POL],
        widgetConfig: {
          showAfterLoggedIn: true,
          showBeforeLoggedIn: false
        }
      };
      state.embedTheme = {
        modalColor: "#f3f3f3",
        bgColor: "#214999",
        bgColorHover: "#f3f3f3",
        textColor: "#f3f3f3",
        textColorHover: "#214999",
        upbondLogin: {
          globalBgColor: "#ffffff",
          globalTextColor: "#214999"
        }
      };
      state.iFrameConfiguration = {
        open: false,
        origin: "",
        src: "",
        topCalc: 3
      };
    },
    setTheme: (state, action: PayloadAction<EmbedTheme>) => {
      state.embedTheme = action.payload;
    },
    setIFrameConfig: (state, action: PayloadAction<{ open: boolean; src: string; origin: string; topCalc: number }>) => {
      state.iFrameConfiguration = action.payload;
    },
    setPopupPayload: (state, action: PayloadAction<any>) => {
      state.popupPayload = action.payload;
    },
    setInitialState: (state) => {
      Object.assign(state, initEmbed);
    }
  }
});

const initEmbedUiState: EmbedUiState = {
  isLoggedIn: false,
  modalIsOpen: false,
  widgetIsOpen: false
};

const embedUiSlice = createSlice({
  name: "embedUiState",
  initialState: initEmbedUiState,
  reducers: {
    setLoginState: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setModalState: (state, action: PayloadAction<boolean>) => {
      state.modalIsOpen = action.payload;
    },
    setWidgetState: (state, action: PayloadAction<boolean>) => {
      state.widgetIsOpen = action.payload;
    }
  }
});

export { embedSlice, embedUiSlice };
